import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    citrusHeightsImage: file(relativePath: { eq: "citrus-heights.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithInCarWideImage: file(
      relativePath: { eq: "locksmith-in-car-wide.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function CitrusHeightsPage({ data }) {
  return (
    <MainLayout
      title="Local Locksmith Service in Citrus Heights, CA | Low Rates"
      description="Need commercial, residential and automotive security? We can help! We offer 24 hour fast friendly locksmith service. Licensed & Insured. 916-995-0135"
      offset
    >
      <ContactHeading
        background={data.citrusHeightsImage.childImageSharp.fluid}
      >
        <h1 className="text-center">Locksmith Citrus Heights</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Citrus Heights Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section" className="pb-0 pb-md-5">
        <h2 className="text-center">Citrus Heights Leading Locksmith</h2>
        <Row>
          <Col xs={12} md={{ span: 6, order: 2 }}>
            <p className="indent">
              Swift Locksmith in Citrus Heights, CA provides complete security
              and quick support to the clients in this area by offering many
              convenient and affordable mobile locksmith services 24/7. We've
              got a highly skilled group of locksmiths to address all kinds of
              lock and key issues economically and bring safety in your home,
              office or car. Our extensive range of services include commercial,
              auto, and residential. Our services are tailored to fit your
              budget and condition, making your life easier.
            </p>
            <p className="indent mb-md-0">
              We realize how disappointed you may feel in the wake of losing
              your home or office key, breaking the vehicle or bicycle key, so
              we work as a mobile locksmith in Citrus Heights region and are
              prepared to accept your call day or night. In addition, we provide
              immediate automobile locksmith services with no hassle in charge
              procedure. Whether it's higher level security systems
              installation/repairing, car key/remotes programming or car vital
              replacement in Citrus Heights, you will have the best possible
              price and satisfaction together with us!
            </p>
          </Col>
          <Col xs={12} md={{ span: 6, order: 1 }} className="col-sm-pad">
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
        </Row>
      </Container>
      <section className="pt-0 pt-md-5">
        <div className="background-content bg-dark text-white pb-0 pb-md-6">
          <Container>
            <h2 className="text-primary text-center">What We Offer</h2>
            <Row>
              <Col xs={12} md={6} className="">
                <p className="indent">
                  We provide local locksmith services such as auto, commercial
                  and home. Here at Swift Locksmith in Citrus Heights, CA, our
                  team takes great pride in providing outstanding security
                  services to all our commercial locksmith customers, as well as
                  our residential locksmith solutions. Our technicians are
                  friendly, confident and respond quickly to our customers'
                  emergency and non-emergency locksmith needs.
                </p>
                <p className="indent mb-md-0">
                  We're fully mobile and cover all the Citrus Heights region.
                  Our trucks are fully equipped with the right gear to get a
                  task done fast and efficiently at your location and at your
                  own convenience. Call us today and see how we could assist
                  you!
                </p>
              </Col>
              <Col xs={12} md={6} className="col-sm-pad">
                <Img
                  fluid={data.locksmithInCarWideImage.childImageSharp.fluid}
                  alt="locksmith-in-car-wide"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2>Choosing Swift Locksmith</h2>
        <p className="indent">
          We make possible efforts to present the highest quality locksmith
          service. We are the prominent company assisting our Citrus Heights
          clients for over 10 years. Unlike other businesses we provide our
          clients with all the professional services along side their charges if
          requested over the telephone so there's no surprises with any hidden
          fees.
        </p>
        <p className="indent mb-0">
          Our company technicians are well experienced and highly proficient,
          therefore you can entrust the security of your home, upon them.
          Moreover we provide service over 30 minutes of your call. We ensure
          prompt and excellent service .We are dependable and fast when quality
          service is your principal goal.
        </p>
      </Container>
    </MainLayout>
  )
}

export default CitrusHeightsPage
